
import { ref } from 'vue'
import { axiosInstance } from '@/plugins/axios'

const requests = ref([])
const loading = ref(false)
const currentPage = ref(1)
const perPage = ref(10)
const totalRecords = ref()
const sharing_type = ref('')
const company = ref(null)
const date = ref(null)


export const useShuttleRequest = () => {

    const fetchRequest = async () => {
        loading.value = true
        requests.value = []
		const { data } = await axiosInstance.get(`/v1/shuttle-requests?page=${currentPage.value}&limit=${perPage.value}&sort[created_at]=desc${company.value ? `&corporate_id=${company.value.id}` : ''}${sharing_type.value ? `&sharing_type=${sharing_type.value}` : ''}${date.value ? `&created_at=${date.value}` : ''}`)
		requests.value = data.data
		totalRecords.value = data.metadata.total
		loading.value = false
	}
	
	return { fetchRequest, requests, loading, currentPage, perPage, totalRecords, sharing_type, company, date }
}

