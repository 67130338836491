<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body p-0">
					<!-- <div class="col-auto d-flex justify-content-end align-items-end px-0">
						<DownloadButton v-if="tableData?.length" :downloading="downloadingReport" label="Download Report"
							@download="downloadReport()" />
					</div> -->
          <div class="flex flex-col lg:flex-row px-4 py-3 rounded-tl-lg rounded-tr-lg gap-4 justify-between lg:items-center bg-white">
            <!-- TODO add search input and date filter -->
            <div class="flex items-center gap-2">
              <label class="m-0 text-sm text-[#6E717C] whitespace-nowrap">Select company</label>
              <multiselect
                v-model="selectedCompany"
                :custom-label="corporateName"
                :loading="companyData.loading.value"
                :searchable="true"
                :options="companyData.corporatesList.value">
              </multiselect>
              <button class="bg-transparent p-1 text-sm outline-none border-0" v-if="selectedCompany" @click="selectedCompany = null">Clear</button>
            </div>
            <div class="flex items-center gap-4">
              <date-picker v-model="date" valueType="format"></date-picker>
              <div class="flex items-center gap-2">
                <label class="m-0 text-sm text-[#6E717C]">Service option</label>
                <select v-model="sharing_type" class="border-0 font-medium">
                  <option value="">All services</option>
                  <option value="exclusive">Exclusive</option>
                  <option value="shared">Shared</option>
                </select>
              </div>
            </div>
            
          </div>
          <b-table ref="staffTable" @row-clicked="viewRequest" :current-page="currentPage" :busy="loading" responsive striped :items="requests" :fields="fields">
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <strong>Loading...</strong>
              </div>
            </template>
            <template #empty>
              <h4>There are no staff found</h4>
            </template>
            
            <template #cell(id)="row">
                {{ row.index+1+(perPage*(currentPage-1)) }}
            </template>
            <template #cell(created_at)="data">
                {{moment(data.item.created_at).format('DD-MM-YYYY') || 'N/A'}}
            </template>
            <template #cell(service_type)="data">
              <p class="whitespace-nowrap text-sm">{{ data.item.service_type == 'pay_per_seat' ? 'Pay per seat' : 'Pay per vehicle' }}</p>
            </template>
            <template #cell(start_date)="data">
              <p class="whitespace-nowrap">
                {{moment(data.item.start_date).format('DD-MM-YYYY') || 'N/A'}}
              </p>
            </template>
            <template #cell(status)="data">
              <p class="whitespace-nowrap text-sm px-2 rounded py-1 text-white w-fit" 
                :class="[data.item.status == 'active' ? ' bg-[#109352]' : ' bg-[#E13D45]']"
              >
                {{ data.item.status == 'active' ? 'Active' : data.item.status == 'deactivated' ? 'Deactivated' : 'Inactive' }}
              </p>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { extractErrorMessage } from '@/utils/helpers'
import { ref, watch } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import Swal from 'sweetalert2'
import moment from 'moment'
import {useShuttleRequest} from '../../composables/allRequest'
import router from '@/router'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

const { loading, fetchRequest, requests, sharing_type, company:selectedCompany, currentPage, perPage, totalRecords, date  } = useShuttleRequest()

const fields = [
  // { key: 'id', label: 'ID' },
  { key: 'id', label: 'S/N' },
  { key: 'name', label: 'ID' },
  { key: 'corporate.corporate_name', label: 'Company' },
  { key: 'created_at', label: 'Date Created' },
  { key: 'service_type', label: 'Service type' },
  { key: 'sharing_type', label: 'Sharing type' },
  { key: 'payment_type', label: 'Payment' },
  { key: 'users.length', label: 'No of staff' },
  { key: 'start_date', label: 'Start date' },
  { key: 'status', label: 'Status' }
]

const companyData = {
	loading: ref(false),
	corporatesList: ref([]),
}

watch([currentPage, selectedCompany, sharing_type, date], () => {
  fetchRequest()
})

const viewRequest = (data) => {
  router.push(`/request/${data.id}`)
}


const corporateName = ({ corporate_name }) => {
	return corporate_name
}


const loadCorporates = async () => {
	companyData.loading.value = true
	const { data } = await axiosInstance.get('/v1/corporates')
	companyData.corporatesList.value = data.data.length ? data.data : []
	companyData.loading.value = false
}

loadCorporates()
fetchRequest()
</script>

<style lang="scss" scoped></style>
